import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { faCircleNotch, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Flex, Text } from "rebass";
import { Input as InputComponent } from "@rebass/forms";
import { css } from "emotion";
import useModal from "../../../hooks/modal";
import Modal from "../../../components/_layout/modal";
import { calculateRatio, resetVideoPlayback } from "../../../utils";
import { hasError } from "../../../utils/validator";
import useStories from "../../../hooks/stories";
import { shake } from "../../../styles/animations";

const Input = ({
  inputType,
  hasDoubleOptin,
  field,
  text,
  placeholder,
  layout,
}) => {
  const [isModalOpen, setIsModalOpen, toggle] = useModal();
  const {
    showInteractionFeedback,
    pages,
    currentStoryIndex,
    videoRefs,
    dispatch,
  } = useStories();

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [validationError, setValidationError] = useState(null);
  const [uuid, setUuid] = useState("");
  const [isPincodeRequired, setIsPincodeRequired] = useState(false);
  const [isPincodeVerified, setIsPincodeVerified] = useState(false);
  const [pincodeValue, setPincodeValue] = useState("");
  const [pincodeRemainingTime, setPincodeRemainingTime] = useState(300);

  const pincodeTimeInterval = useRef();

  const inputTypeMapping = {
    text: "text",
    phone: "number",
    email: "email",
  };

  const inputStyle = css`
    padding-right: ${calculateRatio(62)}px !important;
    background-color: #eeefef !important;
    border: 0 !important;
    padding: ${calculateRatio(8)}px ${calculateRatio(16)}px !important;
    font-size: ${calculateRatio(16)}px !important;

    ${inputType === "phone" && !isPincodeRequired
      ? `
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      `
      : ``}
  `;

  const secondsToTime = (seconds) => {
    const pad = (num, size) => {
      return ("000" + num).slice(size * -1);
    };

    const time = parseFloat(seconds).toFixed(3);
    const min = Math.floor(time / 60) % 60;
    const sec = Math.floor(time - min * 60);

    return pad(min, 2) + ":" + pad(sec, 2);
  };

  const handlePostSubmit = () => {
    let fields = {};

    fields[field] = inputValue;

    dispatch({
      type: "FIELDS_ADD",
      payload: fields,
    });

    dispatch({
      type: "IS_INPUT_FOCUSED_TOGGLE",
      payload: false,
    });

    const nextStoryId = _.get(pages[currentStoryIndex + 1], "id");

    if (videoRefs[nextStoryId] && videoRefs[nextStoryId].current) {
      videoRefs[nextStoryId].current.play();

      dispatch({
        type: "IS_VIDEO_PLAYING_TOGGLE",
        payload: true,
      });
    }

    dispatch({
      type: "LAST_FEATURED_SHOWN_INDEX_SET",
      payload: currentStoryIndex,
    });

    dispatch({
      type: "PAGE_NEXT",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputValue) {
      return;
    }

    resetVideoPlayback({
      videoRefs,
    });

    dispatch({
      type: "IS_VIDEO_PLAYING_TOGGLE",
      payload: false,
    });

    setValidationError(null);

    const error = hasError({
      value: inputValue,
      type: inputType,
      customValidations: {},
    });

    if (error) {
      return setValidationError(error.errorMessage);
    }

    if (inputType === "phone" && !isPincodeVerified && hasDoubleOptin) {
      setIsLoading(true);

      try {
        const response = await axios.post(
          "https://brain-api.spinup.media/api/sms/otp",
          {
            phone: `+39${inputValue}`,
          }
        );

        setUuid(response.data.uuid);

        setPincodeRemainingTime(300);

        pincodeTimeInterval.current = setInterval(() => {
          setPincodeRemainingTime((val) => val - 1);
        }, 1000);

        setIsPincodeRequired(true);

        return setIsLoading(false);
      } catch (e) {
        setValidationError(
          "C'è stato un errore nell'invio del codice di conferma al numero specificato. Riprova!"
        );

        return setIsLoading(false);
      }
    }

    handlePostSubmit();
  };

  const handlePincodeSubmit = async (e) => {
    e.preventDefault();

    if (!pincodeValue) {
      return;
    }

    resetVideoPlayback({
      videoRefs,
    });

    dispatch({
      type: "IS_VIDEO_PLAYING_TOGGLE",
      payload: false,
    });

    setValidationError(null);

    try {
      setIsLoading(true);

      await axios.post(
        `https://brain-api.spinup.media/api/sms/otp/verify/${uuid}`,
        {
          otp: pincodeValue,
        }
      );

      setIsLoading(false);
      setPincodeValue("");
      setIsPincodeRequired(false);
      setIsPincodeVerified(true);
      clearInterval(pincodeTimeInterval.current);
      handlePostSubmit();
    } catch (e) {
      setValidationError("Il codice inserito non è valido");

      return setIsLoading(false);
    }
  };

  useEffect(() => {
    if (pincodeRemainingTime === 0) {
      setIsPincodeRequired(false);
    }
  }, [pincodeRemainingTime]);

  return (
    <>
      <Box
        onFocus={() => {
          dispatch({
            type: "IS_INPUT_FOCUSED_TOGGLE",
            payload: true,
          });
        }}
        onBlur={() => {
          dispatch({
            type: "IS_INPUT_FOCUSED_TOGGLE",
            payload: false,
          });
        }}
        className={css`
          position: ${layout.top || layout.left ? "absolute" : "relative"};
          top: ${layout.top ? `${calculateRatio(layout.top)}px` : "initial"};
          left: ${layout.left ? `${calculateRatio(layout.left)}px` : "initial"};
          border-radius: ${calculateRatio(5)}px;
          max-width: 75%;
          background-color: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
          z-index: 10;
          transition: top 0.15s ease;

          ${showInteractionFeedback &&
          `
          animation: ${shake} 0.5s ease;
        `}
        `}
      >
        <form onSubmit={isPincodeRequired ? handlePincodeSubmit : handleSubmit}>
          <Box
            className={css`
              padding: ${calculateRatio(16)}px;
              text-align: center;
            `}
          >
            {!isPincodeRequired ? (
              <>
                <Text
                  className={css`
                    font-size: ${calculateRatio(16)}px;
                    margin-bottom: ${calculateRatio(8)}px !important;
                  `}
                >
                  {text}
                </Text>

                <Flex flexDirection="row" alignItems="stretch">
                  {inputType === "phone" && (
                    <Flex
                      alignItems="center"
                      sx={{
                        border: "1px solid #cccccc",
                        backgroundColor: "#f6f6f6",
                        color: "#a4a4a4",
                        py: 2,
                        px: 3,
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4,
                      }}
                    >
                      <Text>{"+39"}</Text>
                    </Flex>
                  )}

                  <InputComponent
                    value={inputValue}
                    type={inputTypeMapping[inputType]}
                    placeholder={placeholder}
                    pattern={inputType === "phone" ? `[0-9]*` : null}
                    className={inputStyle}
                    onChange={(e) => {
                      dispatch({
                        type: "LAST_FEATURED_SHOWN_INDEX_SET",
                        payload: currentStoryIndex - 1,
                      });

                      setIsPincodeVerified(false);

                      setInputValue(e.target.value);
                    }}
                  />
                </Flex>

                {inputType === "phone" && hasDoubleOptin && (
                  <Text
                    onClick={() => setIsModalOpen(true)}
                    color="#8c8c8c"
                    mt={2}
                    fontSize="11px"
                    sx={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} /> Disclaimer
                  </Text>
                )}
              </>
            ) : (
              <>
                <Text
                  onClick={() => {
                    clearInterval(pincodeTimeInterval.current);
                    setPincodeValue("");
                    setIsPincodeRequired(false);
                  }}
                  color="primary"
                  className={css`
                    font-size: ${calculateRatio(13)}px;
                    margin-bottom: ${calculateRatio(8)}px !important;
                  `}
                >
                  Torna indietro
                </Text>

                <Text
                  className={css`
                    font-size: ${calculateRatio(16)}px;
                    margin-bottom: ${calculateRatio(8)}px !important;
                  `}
                >
                  Inserisci di seguito il codice ricevuto via SMS entro 5 minuti
                </Text>

                <Box sx={{ position: "relative" }}>
                  <InputComponent
                    value={pincodeValue}
                    type="number"
                    placeholder="Es.: 345430"
                    pattern="[0-9]*"
                    className={inputStyle}
                    onChange={(e) => {
                      setPincodeValue(e.target.value);
                    }}
                  />

                  <Text
                    sx={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      top: 0,
                      bottom: 0,
                      right: 10,
                    }}
                  >
                    {secondsToTime(pincodeRemainingTime)}
                  </Text>
                </Box>
              </>
            )}

            {!!validationError && (
              <Text mt={1} color="danger" fontSize={0}>
                {validationError}
              </Text>
            )}
          </Box>

          <button
            type="submit"
            className={css`
              width: 100%;
              background: transparent;
              padding: ${calculateRatio(12)}px;
              font-size: ${calculateRatio(16)}px !important;
              border: 0;
              border-top: 1px solid #d8d8d8;
              color: #0fa3f0;
              font-weight: 700;
              outline: none;
            `}
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            ) : (
              "Invia"
            )}
          </button>
        </form>
      </Box>

      <Modal
        isActive={isModalOpen}
        toggle={toggle}
        maxWidth={["95vw", "75vw", "50vw"]}
        minWidth={["95vw", "75vw", "50vw"]}
      >
        <Box
          p={3}
          bg="white"
          sx={{ borderRadius: "10px", maxHeight: "75vh", overflowY: "scroll" }}
        >
          <Text fontSize={1}>
            Se decidi di lasciare il tuo numero di telefono per ricevere
            informazioni sul prodotto o servizio richiesto, il tuo numero verrà
            trattato con un software di nome Twilio (link policy privacy di
            Twilio) che ci permette di realizzare la procedura del "double
            optin". Grazie a questa procedura l'utente che si iscrive in una
            lista email deve confermare l'iscrizione attraverso un'azione
            aggiuntiva. Dopo aver inserito il tuo numero di telefono riceverai
            un messaggio sul tuo dispositivo che contiene un codice numerico.
            Una volta ricevuto il codice numerico, ti chiederemo di inserire il
            codice in una finestra per confermare la tua iscrizione. Grazie a
            questo metodo possiamo verificare che i dati inseriti sono validi e
            che sono di effettiva proprietà dell’utente. Twilio non conserva i
            tuoi dati e utilizza il tuo numero solo per generare il codice
            numerico sul tuo dispositivo.
          </Text>

          <Text
            color="primary"
            mt={3}
            textAlign="center"
            sx={{ cursor: "pointer" }}
            onClick={() => setIsModalOpen(false)}
          >
            Chiudi
          </Text>
        </Box>
      </Modal>
    </>
  );
};

export default Input;
