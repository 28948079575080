import React, { useEffect } from "react";
import { css } from "emotion";
import useStories from "../../../hooks/stories";
import { calculateRatio } from "../../../utils";

const Video = ({ orientation, url, pageId }) => {
  const {
    videoRefs,
    isVideoPlaying,
    currentStoryIndex,
    dispatch,
  } = useStories();

  const style = css`
    position: absolute;
    top: 0;
    ${orientation === "portrait" ? "width" : "height"}: 100%;
  `;

  useEffect(() => {
    const pauseVideo = () => {
      dispatch({
        type: "IS_VIDEO_PLAYING_TOGGLE",
        payload: false,
      });
    };

    videoRefs[pageId].current.addEventListener("ended", pauseVideo, true);

    return () => {
      videoRefs[pageId].current.removeEventListener("ended", pauseVideo, true);
    };
  }, [dispatch, videoRefs, pageId]);

  return (
    <>
      <video className={style} ref={videoRefs[pageId]} playsInline>
        <source src={url} type="video/mp4" />
      </video>

      <img
        src="https://i.imgur.com/YsNKoVC.png"
        alt="Play"
        className={css`
          position: absolute;
          z-index: 20;
          width: 100px;
          top: 50%;
          margin-top: -50px;
          cursor: pointer;
          opacity: ${isVideoPlaying ? 0 : 1};
          transition: opacity 0.15s ease;
        `}
        onClick={() => {
          if (isVideoPlaying) {
            videoRefs[pageId].current.pause();

            dispatch({
              type: "IS_VIDEO_PLAYING_TOGGLE",
              payload: false,
            });
          } else {
            videoRefs[pageId].current.play();

            dispatch({
              type: "IS_VIDEO_PLAYING_TOGGLE",
              payload: true,
            });
          }
        }}
      />

      {!isVideoPlaying && currentStoryIndex === 0 ? (
        <img
          alt=""
          src="https://media1.giphy.com/media/WraJqGwrIYzAglP6UJ/giphy.gif"
          className={css`
            position: absolute;
            height: ${calculateRatio(100)}px;
            top: ${calculateRatio(200)}px;
            left: ${calculateRatio(80)}px;
            transform: rotate(-60deg);
          `}
        />
      ) : null}
    </>
  );
};

export default Video;
