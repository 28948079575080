import React from "react";
import Text from "./text";
import Image from "./image";
import Video from "./video";
import Input from "./input";
import Buttons from "./buttons";
import Webhook from "./webhook";
import Iframe from "./iframe";

const Block = (props) => {
  if (props.type === "text") {
    return <Text {...props} />;
  }

  if (props.type === "image") {
    return <Image {...props} />;
  }

  if (props.type === "video") {
    return <Video {...props} />;
  }

  if (props.type === "input") {
    return <Input {...props} />;
  }

  if (props.type === "buttons") {
    return <Buttons {...props} />;
  }

  if (props.type === "webhook") {
    return <Webhook {...props} />;
  }

  if (props.type === "iframe") {
    return <Iframe {...props} />;
  }

  return <></>;
};

export default Block;
