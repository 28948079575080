import React, { useEffect } from "react";
import axios from "axios";
import useStories from "../../../hooks/stories";
import { getQueryStrings } from "../../../utils";

const Webhook = ({ pageId, url, method, params }) => {
  const { pages, currentStoryIndex, conversion, fields } = useStories();

  useEffect(() => {
    const isCurrentStory = pages[currentStoryIndex].id === pageId;

    const webhookCall = async () => {
      if (!isCurrentStory) {
        return;
      }

      console.info(`Triggering webhook call`);

      try {
        await axios({
          method: method,
          url: url,
          data: {
            ...params,
            ...fields,
            ...getQueryStrings(),
            _page_url: window.location.origin + window.location.pathname,
            adverstory: true,
          },
        });

        if (window.fbq && conversion) {
          window.fbq("track", "Lead", conversion);
        }

        if (window.dataLayer && conversion) {
          window.dataLayer.push({
            lead: {
              value: conversion.value,
            },
            event: "sendLead",
          });
        }
      } catch (e) {}
    };

    webhookCall();
  }, [
    pages,
    currentStoryIndex,
    pageId,
    url,
    method,
    params,
    fields,
    conversion,
  ]);

  return <></>;
};

export default Webhook;
