import React, { useState } from "react";
import _ from "lodash";
import { Box, Flex, Text } from "rebass";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Block from "../block";
import useStories from "../../hooks/stories";
import { getBoxSize } from "../../utils";
import config from "../../config";

const Featured = () => {
  const [show, setShow] = useState(false);
  const {
    pages,
    currentStoryIndex,
    lastFeaturedShownIndex,
    dispatch,
  } = useStories();

  const featuredPages = _.filter(pages, (page) => page.featured);

  if (!featuredPages.length || pages.length - 1 === currentStoryIndex) {
    return <></>;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: show ? 0 : -(config.aspectRatio.height * 14 + 16),
        left: 0,
        right: 0,
        height: config.aspectRatio.height * 14 + 16,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1111,
        transition: "bottom 0.25s ease",
      }}
    >
      <Flex
        onClick={() => {
          if (window.dataLayer) {
            window.dataLayer.push({
              event: show ? "navigationHide" : "navigationShow",
            });
          }

          setShow(!show);
        }}
        sx={{
          position: "absolute",
          flexDirection: "column",
          left: "50%",
          marginLeft: "-40px",
          textAlign: "center",
          alignItems: "center",
          fontSize: 4,
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          width: 80,
          bottom: "100%",
          zIndex: 111,
        }}
      >
        <Box
          sx={{
            transform: `rotate(${show ? 180 : 0}deg)`,
            transition: "transform 0.25s ease",
          }}
        >
          <FontAwesomeIcon icon={faChevronUp} color="#fff" />
        </Box>

        <Text
          sx={{
            color: "#fff",
            fontSize: "12px",
          }}
        >
          Navigazione
        </Text>
      </Flex>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pr: "8px",
          overflowX: ["scroll", "auto"],
        }}
      >
        {pages.map(
          (page, i) =>
            (i === 0 || (page.featured && i <= lastFeaturedShownIndex)) && (
              <Box
                key={i}
                onClick={() => {
                  if (window.dataLayer) {
                    window.dataLayer.push({
                      event: "navigationPageChange",
                      navigationPageTitle: i === 0 ? "Inizio" : page.title,
                    });
                  }

                  dispatch({
                    type: "PAGE_GO_TO",
                    payload: i,
                  });
                }}
                sx={{
                  position: "relative",
                  boxSizing: "border-box",
                  // borderColor: i === currentStoryIndex ? "yellow" : "transparent",
                  // borderStyle: "solid",
                  // borderWidth: "2px",
                  width: config.aspectRatio.width * 14,
                  height: config.aspectRatio.height * 14,
                  flexShrink: 0,
                  my: "8px",
                  ml: "8px",
                  borderRadius: "8px",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <Flex
                  sx={{
                    position: "absolute",
                    overflow: "hidden",
                    justifyContent: "center",
                    top: 0,
                    left: 0,
                    width: getBoxSize().width,
                    height: getBoxSize().height,
                    ...page.style,
                    transform: `scale(${
                      (config.aspectRatio.width * 14) / getBoxSize().width
                    })`,
                    transformOrigin: "top left",
                  }}
                >
                  {page.blocks.map((block, index) => (
                    <Block
                      key={`${page.id}-${index}`}
                      pageId={page.id}
                      type={block.type}
                      {...block.props}
                    />
                  ))}

                  <Flex
                    sx={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      alignItems: "flex-end",
                      zIndex: 111,
                      background:
                        "linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, .6) 70%, rgba(0, 0, 0, .8) 100%)",
                    }}
                  >
                    <Text
                      sx={{
                        color: "gainsboro",
                        p: 4,
                        fontSize: "42px",
                        opacity: 0.7,
                        fontWeight: "600",
                        textShadow: "#000 1px 0 10px",
                      }}
                    >
                      {i === 0 ? "Inizio" : page.title}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            )
        )}
      </Box>
    </Box>
  );
};

export default Featured;
