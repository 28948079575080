import React, { useEffect } from "react";
import { css } from "emotion";
import { calculateRatio } from "../../../utils";
import useStories from "../../../hooks/stories";

const Iframe = ({ src, layout }) => {
  const { dispatch } = useStories();

  const style = css`
    position: absolute;
    top: ${calculateRatio(layout.top || 0)}px;
    left: ${calculateRatio(layout.left || 0)}px;
    z-index: 1;
  `;

  useEffect(() => {
    const onMessage = (event) => {
      if (event.data === "form_sent") {
        setTimeout(() => {
          dispatch({
            type: "PAGE_NEXT",
          });
        }, 1000);
      }
    };

    window.addEventListener("message", onMessage);

    return () => window.removeEventListener("message", onMessage);
  }, [dispatch]);

  return (
    <iframe
      title="iframe"
      width={
        layout.width && isNaN(layout.width)
          ? layout.width
          : layout.width && !isNaN(layout.width)
          ? `${calculateRatio(layout.width)}px`
          : "auto"
      }
      height={
        layout.height && isNaN(layout.height)
          ? layout.height
          : layout.height && !isNaN(layout.height)
          ? `${calculateRatio(layout.height)}px`
          : "auto"
      }
      className={style}
      src={src}
      frameBorder="0"
      gesture="media"
      allow="encrypted-media"
    />
  );
};

export default Iframe;
