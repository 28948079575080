import { getQueryStrings } from "./utils";

let storyOverride;

if (getQueryStrings().preview) {
  try {
    storyOverride = {
      avatar: "https://image.flaticon.com/icons/png/512/178/178927.png",
      name: "Preview",
      conversion: {
        value: 10,
        currency: "EUR",
      },
      pages: [
        {
          id: "24ubfe7s",
          style: {
            backgroundColor: "#1a1818",
          },
          blocks: [
            {
              type: "text",
              props: {
                text: "Loading preview...",
                layout: {
                  top: 80,
                  width: "100%",
                  fontSize: 27,
                  fontWeight: 800,
                  textAlign: "center",
                  color: "white",
                },
              },
            },
          ],
        },
      ],
    };
  } catch (e) {}
}

export default storyOverride ||
  window.story || {
    avatar:
      "https://brain-spinup-38481384.s3.eu-west-1.amazonaws.com/uploads/e4c50c87-acdc-4e34-8fad-a7c50bacb19b_1%20%281%29.png",
    name: "CheOfferta.it",
    conversion: {
      value: 10,
      currency: "EUR",
    },
    pages: [
      {
        id: "24ubfe7s",
        style: {
          backgroundColor: "#1a1818",
        },
        blocks: [
          {
            type: "text",
            props: {
              text: "👋 Ciao, benvenuto/a!!",
              layout: {
                top: 80,
                width: "100%",
                fontSize: 27,
                fontWeight: 800,
                textAlign: "center",
                color: "white",
              },
            },
          },
          {
            type: "text",
            props: {
              text:
                "👉 Vuoi trovare la migliore offerta ADSL, Fibra e Telefono e risparmiare fino a 200 € all'anno? 💰",
              layout: {
                top: 175,
                left: 54,
                lineHeight: 1.2,
                width: 300,
                fontSize: 23,
                rotate: -5,
                color: "white",
              },
            },
          },
          {
            type: "buttons",
            props: {
              buttons: [
                {
                  text: "Magari! 😍",
                  class: "animate__animated animate__pulse animate__infinite",
                },
                {
                  text: "Mostrami come fare 😱",
                  class: "animate__animated animate__pulse animate__infinite",
                },
              ],
              layout: {
                top: 350,
              },
            },
          },
          {
            type: "image",
            props: {
              url:
                "https://media1.giphy.com/media/WraJqGwrIYzAglP6UJ/giphy.gif",
              layout: {
                top: 540,
                left: 120,
                width: 40,
                rotate: 200,
              },
            },
          },
        ],
      },
      {
        id: "gfd7gydhw",
        style: {
          backgroundColor: "#1a1818",
        },
        blocks: [
          {
            type: "text",
            props: {
              text:
                "ℹ Per mostrarti le offerte per risparmiare sulla tua linea telefonica e per poterti contattare gratuitamente e senza impegno avrei bisogno del tuo consenso alla nostra privacy",
              layout: {
                top: 130,
                left: 25,
                width: 350,
                fontSize: 20,
                rotate: 0,
                textAlign: "center",
                color: "white",
              },
            },
          },
          {
            type: "buttons",
            props: {
              buttons: [
                {
                  text: "Visualizza privacy policy",
                  url:
                    "https://www.tiscali.it/privacy/informativa_policy_privacy.html",
                  layout: {
                    fontWeight: 300,
                    backgroundColor: "transparent",
                    padding: 10,
                    border: "none",
                    fontSize: 12,
                    color: "#d5d5d5",
                  },
                },
                {
                  text: "Acconsento 👍",
                  class: "animate__animated animate__pulse animate__infinite",
                  layout: {
                    backgroundColor: "#4bb238",
                    color: "#fff",
                    border: "3px solid #fff",
                    borderRadius: 0,
                  },
                },
                {
                  text: "Non acconsento",
                  toast: {
                    message:
                      "Ci dispiace che tu abbia negato il consenso. SENZA consenso non potremo contattarti per inviarti le promozioni",
                    appearance: "error",
                  },
                  layout: {
                    fontWeight: 300,
                    backgroundColor: "transparent",
                    padding: 10,
                    border: "none",
                    fontSize: 12,
                    color: "#d5d5d5",
                  },
                },
              ],
              layout: {
                top: 330,
              },
            },
          },
        ],
      },
      {
        id: "102jsadf34",
        featured: true,
        title: "Selezione provider",
        style: {
          backgroundColor: "#1a1818",
        },
        blocks: [
          {
            type: "text",
            props: {
              text: "🔥 Ci siamo!",
              layout: {
                top: 80,
                left: 80,
                width: 280,
                fontSize: 24,
                fontWeight: 800,
                rotate: -3,
                color: "white",
              },
            },
          },
          {
            type: "text",
            props: {
              text: `⭕ Ma prima una domanda per poterti proporre la migliore promo ADSL, Fibra e Telefono disponibile sul mercato: quale operatore hai attualmente a casa? Seleziona in basso! 👇
            `,
              layout: {
                top: 160,
                left: 30,
                width: 340,
                fontSize: 16,
                color: "white",
              },
            },
          },
          {
            type: "buttons",
            props: {
              field: "provider",
              buttons: [
                {
                  text: "Fastweb",
                  value: "Fastweb",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 10,
                    fontSize: 14,
                  },
                },
                {
                  text: "TIM",
                  value: "TIM",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 10,
                    fontSize: 14,
                  },
                },
                {
                  text: "Vodafone",
                  value: "Vodafone",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 10,
                    fontSize: 14,
                  },
                },
                {
                  text: "Tiscali",
                  value: "Tiscali",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 10,
                    fontSize: 14,
                  },
                },
                {
                  text: "Infostrada",
                  value: "Infostrada",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 10,
                    fontSize: 14,
                  },
                },
              ],
              layout: {
                top: 330,
                width: 160,
                left: 30,
              },
            },
          },
          {
            type: "buttons",
            props: {
              field: "provider",
              buttons: [
                {
                  text: "WindTre",
                  value: "WindTre",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 10,
                    fontSize: 14,
                  },
                },
                {
                  text: "Altro",
                  value: "Altro",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 10,
                    fontSize: 14,
                  },
                },
                {
                  text: "Nessuno",
                  value: "Nessuno",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 10,
                    fontSize: 14,
                  },
                },
                {
                  text: "Prima attivazione",
                  value: "Prima attivazione",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 10,
                    fontSize: 14,
                  },
                },
              ],
              layout: {
                top: 330,
                width: 160,
                left: 205,
              },
            },
          },
        ],
      },
      {
        id: "102jsdf34",
        featured: true,
        title: "Selezione spesa",
        style: {
          backgroundColor: "#1a1818",
        },
        blocks: [
          {
            type: "text",
            props: {
              text: `📣 Per calcolare la tariffa migliore per te, seleziona in basso quanto spendi attualmente al mese! ⬇
            `,
              layout: {
                top: 120,
                left: 40,
                width: 320,
                fontSize: 18,
                color: "white",
              },
            },
          },
          {
            type: "buttons",
            props: {
              field: "spend",
              buttons: [
                {
                  text: "25€/mese",
                  value: "25",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 15,
                    fontSize: 14,
                  },
                },
                {
                  text: "35€/mese",
                  value: "35",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 15,
                    fontSize: 14,
                  },
                },
                {
                  text: "Fino a 50€/mese",
                  value: "till50",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 15,
                    fontSize: 14,
                  },
                },
                {
                  text: "Oltre 50€/mese",
                  value: "more50",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 15,
                    fontSize: 14,
                  },
                },
                {
                  text: "Non ricordo",
                  value: "notdefined",
                  class: "",
                  layout: {
                    fontWeight: 300,
                    padding: 15,
                    fontSize: 14,
                  },
                },
              ],
              layout: {
                top: 270,
                width: 320,
                left: 30,
              },
            },
          },
        ],
      },
      {
        id: "gdf67shfsf2",
        featured: true,
        title: "Inserimento nome",
        style: {
          backgroundColor: "#1a1818",
        },
        blocks: [
          {
            type: "text",
            props: {
              text: "Ottimo, ma ora presentiamoci: come ti chiami? 😀",
              layout: {
                lineHeight: 1.3,
                top: 79,
                left: 40,
                width: 310,
                textAlign: "center",
                fontSize: 24,
                color: "white",
              },
            },
          },
          {
            type: "input",
            props: {
              field: "first_name",
              text: "Inserisci il tuo nome e cognome qui in basso! ⬇",
              inputType: "text",
              placeholder: "es.: Mario Rossi",
              layout: {
                top: 200,
              },
            },
          },
          {
            type: "image",
            props: {
              url:
                "https://media3.giphy.com/media/R6xi8dXsRhIjK/giphy.gif?cid=ecf05e47ova2t85k7nbbk07i7o1e9tib9qnavycgkux43obf&rid=giphy.gif",
              layout: {
                top: 400,
                left: 0,
                width: 393,
              },
            },
          },
        ],
      },

      {
        id: "3mfsd9fsdj8",
        featured: true,
        title: "Inserimento telefono",
        style: {
          backgroundColor: "#1a1818",
        },
        blocks: [
          {
            type: "text",
            props: {
              text:
                "Puoi scrivermi qui sotto il tuo numero di telefono cellulare?",
              layout: {
                top: 86,
                left: 70,
                width: 240,
                textAlign: "center",
                fontSize: 18,
                color: "white",
              },
            },
          },
          {
            type: "input",
            props: {
              field: "phone",
              text: "Inserisci il tuo numero di telefono cellulare qua:",
              inputType: "phone",
              placeholder: "es.: 3401234567",
              countryCode: "+39",
              layout: {
                top: 200,
              },
            },
          },
          {
            type: "image",
            props: {
              url:
                "https://media3.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif?cid=ecf05e47wn89yjvfz47aam97lttm8aftu82pbdmrp38u59j8&rid=giphy.gif",
              layout: {
                top: 303,
                left: 0,
                width: 393,
              },
            },
          },
        ],
      },

      {
        id: "zxcijsf2302",
        style: {
          backgroundColor: "#1a1818",
        },
        blocks: [
          {
            type: "text",
            props: {
              text:
                "Questi sono i dati da te inseriti, ne confermi la correttezza?",
              layout: {
                top: 85,
                left: 60,
                width: 280,
                fontSize: 22,
                fontWeight: 800,
                color: "white",
              },
            },
          },
          {
            type: "text",
            props: {
              text: `**Nome e cognome:** {{first_name}}

**Numero di telefono:** {{phone}}
           `,
              layout: {
                top: 240,
                left: 40,
                width: 300,
                fontSize: 20,
                color: "white",
              },
            },
          },
          {
            type: "buttons",
            props: {
              buttons: [
                {
                  text: "Conferma",
                },
                {
                  text: "Voglio reinserire i dati",
                  navigateToIndex: 4,
                },
              ],
              layout: {
                top: 435,
              },
            },
          },
        ],
      },

      {
        id: "456jn5i43n5",
        style: {
          backgroundColor: "#1a1818",
        },
        disableBackNavigation: true,
        blocks: [
          {
            type: "image",
            props: {
              url: "https://media1.giphy.com/media/l3vR4yk0X20KimqJ2/giphy.gif",
              layout: {
                top: 0,
                left: 0,
                width: 393,
                height: 625,
              },
            },
          },
          {
            type: "text",
            props: {
              text:
                "Perfetto, un nostro operatore provvederà a contattarti entro pochi minuti!",
              layout: {
                top: 140,
                left: 46,
                width: 300,
                fontSize: 32,
                rotate: 0,
                lineHeight: 1.3,
                backgroundColor: "#1a1818",
                paddingTop: 30,
                paddingBottom: 30,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 5,
                color: "white",
                textAlign: "center",
              },
            },
          },
        ],
      },
    ],
  };
