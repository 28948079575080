import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "emotion";
import { Box, Card, Flex } from "rebass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const bodyClass = css`
  overflow: hidden !important;
`;

const Modal = ({
  isActive,
  toggle,
  maxWidth = 600,
  minWidth = "initial",
  overlayBackgroundColor = "rgba(0, 0, 0, 0.5)",
  disableOverlay,
  disableLayout,
  children,
}) => {
  useEffect(() => {
    document.body.classList[isActive ? "add" : "remove"](bodyClass);
  }, [isActive]);

  const Overlay = ({ children }) => {
    return disableOverlay ? (
      <>{children}</>
    ) : (
      <div
        className={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: ${overlayBackgroundColor};
          z-index: 10000;
        `}
      >
        {children}
      </div>
    );
  };

  const Layout = ({ children }) => {
    return (
      <Flex
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10001,
        }}
        alignItems="center"
        justifyContent="center"
      >
        {disableLayout ? (
          <>{children}</>
        ) : (
          <>
            <Card
              bg="transparent"
              mx={[4, 6]}
              p={[4, 6]}
              maxWidth={maxWidth}
              minWidth={minWidth}
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "fixed",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                  zIndex: 10001,
                }}
                color="white"
                onClick={() => toggle()}
              >
                <FontAwesomeIcon icon={faTimesCircle} size="2x" />
              </Box>

              {children}
            </Card>
          </>
        )}
      </Flex>
    );
  };

  return isActive ? (
    <Overlay>
      <Layout>
        <Box>{children}</Box>
      </Layout>
    </Overlay>
  ) : null;
};

Modal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  minWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  disableOverlay: PropTypes.bool,
  disableLayout: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default Modal;
