import _ from "lodash";

export const hasError = ({ value, customValidations, type }) => {
  switch (type) {
    case "text": {
      const regex = _.get(customValidations, "regex.value");
      const minChars = _.get(customValidations, "minChars.value");
      const maxChars = _.get(customValidations, "maxChars.value");

      if (minChars && value.length < parseInt(minChars)) {
        return {
          errorCode: "LESS_THAN_MIN_CHARS",
          errorMessage: `Il testo dev'essere lungo almeno ${minChars} caratteri`,
        };
      }

      if (maxChars && value.length > parseInt(maxChars)) {
        return {
          errorCode: "MORE_THAN_MAX_CHARS",
          errorMessage: `Il testo dev'essere lungo al massimo ${maxChars} caratteri`,
        };
      }

      if (regex && !new RegExp(regex).test(value)) {
        console.log("value", value, regex, new RegExp(regex).test(value));
        return {
          errorCode: "INVALID_CHARACTERS",
          errorMessage: `Il testo inserito contiene caratteri non validi`,
        };
      }

      return false;
    }

    case "email": {
      const regexp = /\S+@\S+\.\S+/g;

      if (!regexp.test(value)) {
        return {
          errorCode: "INVALID_EMAIL",
          errorMessage: `L'indirizzo email inserito non è valido`,
        };
      }

      return false;
    }

    case "phone": {
      const regexp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i;

      if (!regexp.test(value)) {
        return {
          errorCode: "INVALID_PHONE",
          errorMessage: `Il numero inserito non è valido`,
        };
      }

      return false;
    }

    default: {
      return false;
    }
  }
};
