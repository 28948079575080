import React from "react";
import marked from "marked";
import { Box } from "rebass";
import { css } from "emotion";
import { calculateRatio, replacePlaceholders } from "../../../utils";
import useStories from "../../../hooks/stories";

const Text = ({ text, layout }) => {
  const { fields } = useStories();

  const style = css`
    position: absolute;
    text-align: ${layout.textAlign || "left"};
    padding-top: ${calculateRatio(layout.paddingTop || 0)}px;
    padding-bottom: ${calculateRatio(layout.paddingBottom || 0)}px;
    padding-left: ${calculateRatio(layout.paddingLeft || 0)}px;
    padding-right: ${calculateRatio(layout.paddingRight || 0)}px;
    border-radius: ${calculateRatio(layout.borderRadius || 0)}px;
    top: ${calculateRatio(layout.top || 0)}px;
    left: ${calculateRatio(layout.left || 0)}px;
    font-size: ${calculateRatio(layout.fontSize || 16)}px;
    font-weight: ${layout.fontWeight || 500};
    font-style: ${layout.fontStyle || "initial"};
    width: ${layout.width && isNaN(layout.width)
      ? layout.width
      : layout.width && !isNaN(layout.width)
      ? `${calculateRatio(layout.width)}px`
      : "auto"};
    color: ${layout.color || "white"};
    background-color: ${layout.backgroundColor || "transparent"};
    transform: rotate(${layout.rotate || 0}deg);

    ${layout.lineHeight &&
    `
      line-height: ${layout.lineHeight};
    `}
  `;

  return (
    <Box className={style}>
      <div
        dangerouslySetInnerHTML={{
          __html: marked(replacePlaceholders({ text, variables: fields })),
        }}
      />
    </Box>
  );
};

export default Text;
