import React from "react";
import { injectGlobal } from "emotion";
import LogRocket from 'logrocket';
import { ToastProvider } from "react-toast-notifications";
import ThemeProvider from "./context/theme";
import Stories from "./components/stories";
import { StoriesProvider } from "./context/stories";

LogRocket.init('efg3md/adverstory');

function App() {
  injectGlobal(`
    body {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  `);

  return (
    <ToastProvider placement="bottom-right">
      <StoriesProvider>
        <ThemeProvider>
          <Stories />
        </ThemeProvider>
      </StoriesProvider>
    </ToastProvider>
  );
}

export default App;
