import React from "react";
import { Image as ImageComponent } from "rebass";
import { css } from "emotion";
import { calculateRatio } from "../../../utils";

const Image = ({ url, layout }) => {
  const style = css`
    position: absolute;
    top: ${calculateRatio(layout.top || 0)}px;
    left: ${calculateRatio(layout.left || 0)}px;
    width: ${layout.width
      ? `${calculateRatio(layout.width)}px !important`
      : "auto"};
    height: ${layout.height
      ? `${calculateRatio(layout.height)}px !important`
      : "auto"};
    transform: rotate(${layout.rotate || 0}deg);
  `;

  return <ImageComponent src={url} className={style} />;
};

export default Image;
