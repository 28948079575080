import React from "react";
import { ThemeProvider } from "theme-ui";
import preset from "@rebass/preset";
import config from "../../config";

export default (props) => {
  let theme = {
    ...preset,
  };

  theme.breakpoints = config.breakpoints.map((breakpoint) => `${breakpoint}px`);

  theme.forms = {
    input: {
      color: "black",
      backgroundColor: "white",
      borderColor: "#cccccc",
      outline: "none",
      p: 3,
      fontSize: 2,
    },
  };

  theme.buttons = {
    ...theme.buttons,
    primary: {
      ...theme.buttons.primary,
      outline: "none !important",
      cursor: "pointer",
      py: 3,
      px: 4,
      mb: 2,
    },
  };

  theme.colors = {
    ...theme.colors,
    background: "#111",
    primary: "#0fa3f0",
    secondary: "#141623",
    danger: "#e00000",
    gold: "#fcb941",
    silver: "#bdc3c7",
    bronze: "#bf7731",
  };

  console.log(theme);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
