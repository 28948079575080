import { keyframes } from "emotion";

export const shake = keyframes`
  0% {
    transform: rotate(-5deg);
  }

  20% {
    transform: rotate(5deg);
  }

  40% {
    transform: rotate(-5deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }

`;
