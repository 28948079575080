const config = {
  breakpoints: [768, 1024, 1216],
  aspectRatio: {
    width: 9,
    height: 14,
  },
  defaultSize: { width: 393, height: 619 },
};

export default config;
